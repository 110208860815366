<template>
  <div>
    <b-card>
      <b-row class="justify-content-between">
        <b-col class="pr-md-32 pr-md-120">
          <h4>
            {{
              isEditPage ? "Ubah Data Barang Masuk" : "Tambah Data Barang Masuk"
            }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-form @submit.prevent="submitAddorEditData">
          <b-form-group label="Batch Barang:">
            <b-input-group prepend="UTD">
              <b-form-input
                v-model="incomingStock.batch"
                :disabled="isEditPage"
                type="text"
                placeholder="Masukkan Batch Barang"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group label="Kode Barang:">
            <v-select
              v-model="incomingStock.kodeBarang"
              :disabled="isEditPage"
              :options="typeStocks"
              label="item_name"
              :reduce="(item) => item.item_code"
              :get-option-label="
                (item) => `${item.item_code} - ${item.item_name}`
              "
              placeholder="Pilih Kode Barang"
              aria-placeholder="Pilih Kode Barang"
            >
              <template #option="{ item_code, item_name }">
                <span>{{ item_code }} - {{ item_name }}</span>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group label="Stok Masuk:">
            <b-form-input
              v-model="incomingStock.qty"
              :disabled="isEditPage"
              type="number"
              placeholder="Masukkan Jumlah Barang"
            />
          </b-form-group>
          <b-form-group label="Tanggal Kadaluarsa:">
            <b-form-input
              v-model="incomingStock.expired"
              type="date"
              placeholder="dd-mm-yyyy"
            />
          </b-form-group>
          <b-form-group label="Catatan:">
            <b-form-textarea
              v-model="incomingStock.note"
              rows="5"
              placeholder="Catatan Tertentu"
            />
          </b-form-group>
          <b-form-group label="Status:">
            <b-form-select
              v-model="incomingStock.status"
              :options="[
                { value: 'passed', text: 'Passed' },
                { value: 'hold', text: 'Hold' },
              ]"
            />
          </b-form-group>
          <div class="d-flex justify-content-end">
            <base-button
              text="Simpan"
              :is-busy="isBusy"
              :loading="isBusy"
              :disabled="!isFormValid || isBusy"
              variant="success-1"
              submit-type="submit"
            />
          </div>
        </b-form>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCard,
  BButton,
  BFormInvalidFeedback,
  BInputGroup,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: "AddIncomingStockGoods",
  components: {
    BFormInvalidFeedback,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BCard,
    vSelect,
    BFormSelect,
  },
  data() {
    return {
      isBusy: false,
      incomingStock: {
        batch: "",
        kodeBarang: "",
        qty: "",
        expired: "",
        note: "",
        status: "",
      },
      typeStocks: [],
      isEditPage: false,
    };
  },
  computed: {
    stocks() {
      return this.$store.state.manageTypeInventory.allListsOfTypeInventory;
    },
    stock() {
      return this.$store.state.manageTypeInventory.typeInventory;
    },
    isFormValid() {
      return (
        this.incomingStock.batch &&
        this.incomingStock.kodeBarang &&
        this.incomingStock.qty &&
        this.incomingStock.qty > 0 &&
        this.incomingStock.expired
      );
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.isEditPage = true;
      this.fetchDetail();
    }
    this.fetchType();
  },
  methods: {
    async submitAddorEditData() {
      if (this.isEditPage) {
        await this.updateIncomingStock();
      } else {
        await this.storeIncomingStock();
      }
    },
    async storeIncomingStock() {
      try {
        this.isBusy = true;
        const formData = new FormData();
        formData.append("batch", "UTD-" + this.incomingStock.batch);
        formData.append("item_code", this.incomingStock.kodeBarang);
        formData.append("total", this.incomingStock.qty);
        formData.append("exp_date", this.incomingStock.expired);
        formData.append("status", "passed");
        formData.append("note", this.incomingStock.note);

        console.log(formData);
        await this.$store
          .dispatch("incomingTypeInventory/storeIncomingInventory", formData)
          .then(() => {
            this.$router.push({ path: "/master-data/barang-masuk" });

            this.$bvToast.toast("Data Barang Masuk Berhasil Ditambahkan", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          });
      } catch (e) {
        const errMsg = e.response.data.message;
        const errMsgIfExpiredDate =
          "The exp date must be a date after or equal to today.\n";

        this.$bvToast.toast(
          errMsg === errMsgIfExpiredDate
            ? "Tanggal Kadaluarsa Tidak Boleh Mundur ataupun Sama Dengan Hari Ini"
            : errMsg,
          {
            title: "Data Gagal Ditambahkan",
            variant: "danger",
            solid: true,
          }
        );
      } finally {
        this.isBusy = false;
      }
    },
    async updateIncomingStock() {
      if (this.isEditPage) {
        try {
          this.isBusy = true;
          const formData = new FormData();
          formData.append("id", this.$route.params.id);
          formData.append("batch", this.incomingStock.batch);
          formData.append("item_code", this.incomingStock.kodeBarang);
          formData.append("total", this.incomingStock.qty);
          formData.append("exp_date", this.incomingStock.expired);
          formData.append("note", this.incomingStock.note);
          formData.append("status", this.incomingStock.status);

          await this.$store
            .dispatch("incomingTypeInventory/updateIncomingInventory", formData)
            .then(() => {
              this.$router.push({ path: "/master-data/barang-masuk" });

              this.$bvToast.toast("Data Barang Masuk Berhasil Diubah", {
                title: "Success",
                variant: "success",
                solid: true,
              });
            });
        } catch (e) {
          const errMsg = e.response.data.message;
          const errMsgIfExpiredDate =
            "The exp date must be a date after or equal to today.\n";

          this.$bvToast.toast(
            errMsg === errMsgIfExpiredDate
              ? "Tanggal Kadaluarsa Tidak Boleh Mundur ataupun Sama Dengan Hari Ini"
              : errMsg,
            {
              title: "Data Gagal Ditambahkan",
              variant: "danger",
              solid: true,
            }
          );
        } finally {
          this.isBusy = false;
        }
      }
    },
    async fetchType() {
      await this.$store.dispatch("manageTypeInventory/getAllTypeInventorys");

      this.typeStocks = this.stocks;
    },
    async fetchDetail() {
      try {
        const incomingInventoryId = this.$route.params.id;
        const response = await this.$store.dispatch(
          "incomingTypeInventory/getDetailIncoming",
          incomingInventoryId
        );
        this.incomingStock.batch = response.batch;
        this.incomingStock.kodeBarang = response.item_code;
        this.incomingStock.qty = response.incoming_stock;
        this.incomingStock.expired = response.exp_date;
        this.incomingStock.status = response.status;
        this.incomingStock.note = response.note;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
